@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Quicksand", sans-serif;
  color: theme("colors.dark-denim");
  overflow-x: hidden;
  width: 100vw;
  line-height: 20px;
}

.slick-list {
  padding: 1rem !important;
}

/**/
:root {
  --navy-blue: #096784;
  --dark-denim: #2b2d3a;
  --princeton-orange: #ff8329;
  --lime-green: #69e269;
  --neon-pink: #fa3676;
  --bayside-cyan: #40d3c1;
}

.navbar {
  padding: 0;
}

.navbar li:hover {
  background-color: theme("colors.dark-denim");
}

.home-divisions .princeton-orange:hover {
  border-color: theme("colors.princeton-orange");
}
.home-divisions .lime-green:hover {
  border-color: theme("colors.lime-green");
}
.home-divisions .bayside-cyan:hover {
  border-color: theme("colors.bayside-cyan");
}
.home-divisions .neon-pink:hover {
  border-color: theme("colors.neon-pink");
}

.division-about {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.division-service-ids {
  scroll-margin-top: 100px;
}

div.max-w-lg {
  max-width: unset;
  z-index: 10;
}

.bg-blog {
  background-image: url("assets/img/blog/bg-mobile.svg");
}

@screen md {
  .bg-blog {
    background-image: url("assets/img/blog/bg.svg");
  }
}

.media-lib {
  mask-image: linear-gradient(
    rgba(0, 0, 0, 1),
    transparent 0,
    white 10%,
    white 90%,
    transparent 100%
  );
}

.media-lib::-webkit-scrollbar {
  display: none;
}

/* loader & message styling */
.loading img {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 100px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  z-index: 5000;
}

.message {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  -o-transform: translate3d(-50%, -50%, 0);
}

.message .message-body {
  background-color: var(--navy-blue);
  color: white;
  width: 100%;
  padding: 50px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: justify;
}

.message h6,
.message h6 {
  text-transform: none;
  font-weight: 400;
}

.loading {
  margin: 0 auto;
}

.heading-inputs,
.heading-inputs::placeholder {
  color: var(--navy-blue);
  font-size: large;
  font-weight: bold;
}

.heading-inputs::placeholder {
  opacity: 30%;
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5000;
  width: 100%;
  height: 100vh;
}

.popup .overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--dark-denim);
  opacity: 90%;
}

.popup .container {
  z-index: 10;
}

.popup label,
.popup button,
label,
button {
  cursor: pointer;
}

.add-visual-container {
  height: 540px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
}

.media-lib {
  z-index: 20;
  height: 520px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}

.media-file {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

/**/

@layer base {
  .app-nav-link:not(.active) {
    @apply bg-transparent;
  }
  .division-links .active {
    color: white;
  }
  .app-nav-desktop-links .active {
    @apply bg-gray-400;
  }
  .with-transition {
    @apply transition-all ease-in duration-500;
  }
}
